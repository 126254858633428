<template>
  <div class="jump-address-container">
    <a-modal
      :visible="visibleModal"
      class="modal-style"
      @cancel="cancel"
      :maskClosable="false"
      :width="1490"
    >
      <div>跳转地址</div>
      <a-divider />
      <template slot="footer">
        <a-button @click="cancel">返回</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <a-row class="mb2">
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="商品名称">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.goodsName"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="商品分类">
            <a-cascader
              v-model="queryFrom.goodsCategoryId"
              placeholder="请选择"
              style="width: 28rem"
              expand-trigger="hover"
              :display-render="displayRender"
              :options="treeData"
              :field-names="{
                label: 'label',
                value: 'id',
                children: 'children',
              }"
            >
            </a-cascader>
          </a-form-model-item>
          <a-form-model-item label="工厂名称">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.factoryName"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>

          <div class="right" style="line-height: 4rem">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>

      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 600 }"
        :pagination="pagination"
        :rowClassName="setBackground"
        :customRow="
          (record, index) => {
            return {
              on: {
                click: (event) => onRowClick(event, record, index),
              },
            };
          }
        "
      >
        <template slot="goodsName" slot-scope="text, record, index">
          <a-radio
            :checked="preIndex === index"
            @change="handleRadioChange(index)"
          ></a-radio>
          <span :title="text">{{ text }}</span>
        </template>
        <template slot="factoryName" slot-scope="text, record">
          <span
            :title="record.factoryName"
            :style="{ color: `${getColor('主题')}` }"
            >{{ text }}</span
          >
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品名称",
    ellipsis: true,
    dataIndex: "goodsName",
    scopedSlots: { customRender: "goodsName" },
  },
  {
    title: "商品分类",
    ellipsis: true,
    dataIndex: "goodsCategoryName",
  },
  {
    title: "申请时间",
    ellipsis: true,
    dataIndex: "applyTime",
  },
  {
    title: "基础价",
    ellipsis: true,
    dataIndex: "onPrice",
  },
  {
    title: "定制起订量",
    ellipsis: true,
    dataIndex: "minOrder",
  },
  {
    title: "可售区域",
    ellipsis: true,
    dataIndex: "availableArea",
  },
  {
    title: "工厂名称",
    ellipsis: true,
    dataIndex: "factoryName",
    scopedSlots: { customRender: "factoryName" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "../../utils/color";
import { filterOpt } from "@/utils/utils";
import { API } from "@/apix/advertisement";

export default {
  mixins: [pagination, mixins],
  props: {
    visibleModal: {
      default: false,
      type: Boolean,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      treeData: [], // 商品分类
      queryFrom: {
        goodsName: null, // 商品名称
        goodsCategoryId: null, // 商品分类
        factoryName: null, // 工厂名称
      },
      goodsId: null, // 单击记录表格行的id，（对比id，改变表格行颜色）
      preIndex: null, // 记录上一次是选择的那行商品(索引)
      goodsCategoryId: null,
    };
  },
  methods: {
    filterOpt,
    getColor,
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    getSourceData() {
      if (this.queryFrom.goodsCategoryId !== null) {
        this.goodsCategoryId =
          this.queryFrom.goodsCategoryId[
            this.queryFrom.goodsCategoryId.length - 1
          ];
      }
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
        goodsCategoryId: this.goodsCategoryId,
      };
      this.loading = true;
      API.goodsJumpAddress(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      this.goodsCategoryId = null;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.getSourceData();
    },
    // 获取商品分类数据
    getGoodsType() {
      API.goodsCategory().then((res) => {
        this.treeData = res.data;
      });
    },
    // 处理表格点击事件
    onRowClick(event, record, index) {
      event.stopPropagation(); //阻止默认事件
      this.preIndex = index;
      this.goodsId = record.goodsId;
    },
    // 设置表格行的背景颜色
    setBackground(record) {
      return record.goodsId === this.goodsId ? "tableActive" : "";
    },
    // 选中当前行
    handleRadioChange(index) {
      this.preIndex = index;
    },
    // 处理选中确定
    handleSubmit() {
      let data = this.data[this.preIndex];
      if (typeof data === "undefined") {
        this.$message.error("请选择一个商品");
      } else {
        // 扔到外层
        this.$emit("goodsData", data);
        this.cancel();
      }
    },
  },
  created() {
    this.getGoodsType();
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.jump-address-container {
}
.ant-input {
  width: 28rem;
}
</style>
