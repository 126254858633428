import axios from "@/config/http.js";

export const API = {
  // 高级-普通爆品信息查询
  async pageIndex(params) {
    return await axios.get("/manage/explosive/list", { params });
  },
  // 普通爆品信息保存
  async addOrdinary(params) {
    return await axios.put("/manage/explosive/save/common", params);
  },
  // 高级爆品信息保存
  async addSenior(params) {
    return await axios.put("/manage/explosive/save/high", params);
  },
  // 删除爆品
  async deleteSenior(id) {
    return await axios.delete(`/manage/explosive?explosiveGoodsId=${id}`);
  },
  // 编辑商品级别
  async editRank(params) {
    return await axios.put(`/manage/explosive/updateRank`, params);
  },
};
