<template>
  <div class="explosive-manage-container">
    <div class="container">
      <a-tabs default-active-key="1" @change="handleTabChange">
        <a-tab-pane key="1" tab="高级">
          <a-button class="mb2 mr2" @click="handleAdd">添加</a-button>
          <a-button class="mb2" type="primary" @click="handleSave('高级')"
            >保存</a-button
          >
          <a-table
            :columns="columns"
            :data-source="data"
            :key="data.key"
            :customRow="customRow"
            :rowClassName="setBackground"
            :loading="loading"
            :scroll="{ y: 630 }"
            :pagination="false"
            v-if="tabVisible"
          >
            <template slot="coverImages" slot-scope="text, record">
              <UploadImage
                :key="record.explosiveGoodsId"
                v-model="record.coverImages"
                :config="uploadConfig"
              />
            </template>
            <template slot="goodsId" slot-scope="text, record, index">
              <span
                class="hover"
                :style="{ color: `${getColor('主题')}` }"
                :title="record.goodsName"
                @click="openModal(record.goodsId, index)"
                >{{ record.goodsName ? record.goodsName : "跳转地址" }}</span
              >
            </template>
            <template slot="periodValidity" slot-scope="text, record">
              <a-date-picker
                @change="(date) => handleDate(date, record)"
                :allowClear="false"
                format="YYYY-MM-DD"
                v-model="record.periodValidity"
              />
            </template>
            <template slot="ranks" slot-scope="text, record">
              <a-switch
                :disabled="goodsAddDis"
                :checked="text === 1 ? true : false"
                @change="handleSwitch(record, 0)"
              />
            </template>
            <template slot="validity" slot-scope="text, record">
              <span :style="{ color: `${getColor(record.validity)}` }">{{
                record.validity
              }}</span>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <span
                class="hover"
                :style="{ color: `${getColor('主题')}` }"
                @click="onDelete(index, record)"
                >删除</span
              >
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="0" tab="普通" force-render>
          <a-button class="mb2 mr2" @click="handleAdd">添加</a-button>
          <a-button class="mb2" type="primary" @click="handleSave('普通')"
            >保存</a-button
          >
          <a-table
            :columns="columns"
            :data-source="data"
            :key="data.key"
            :loading="loading"
            :rowClassName="setBackground"
            :scroll="{ y: 630 }"
            :pagination="false"
            v-if="tabVisible"
          >
            <template slot="coverImages" slot-scope="text, record">
              <UploadImage
                :key="record.explosiveGoodsId"
                v-model="record.coverImages"
                :config="uploadConfig"
              />
            </template>
            <template slot="goodsId" slot-scope="text, record, index">
              <span
                class="hover"
                :style="{ color: `${getColor('主题')}` }"
                @click="openModal(record.goodsId, index)"
                >{{ record.goodsName ? record.goodsName : "跳转地址" }}</span
              >
            </template>
            <template slot="periodValidity" slot-scope="text, record">
              <a-date-picker
                @change="(date) => handleDate(date, record)"
                :allowClear="false"
                format="YYYY-MM-DD"
                v-model="record.periodValidity"
              />
            </template>
            <template slot="ranks" slot-scope="text, record">
              <a-switch
                :disabled="goodsAddDis"
                :checked="text === 1 ? true : false"
                @change="handleSwitch(record, 1)"
              />
            </template>
            <template slot="validity" slot-scope="text, record">
              <span :style="{ color: `${getColor(record.validity)}` }">{{
                record.validity
              }}</span>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <span
                class="hover"
                :style="{ color: `${getColor('主题')}` }"
                @click="onDelete(index, record)"
                >删除</span
              >
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 跳转页面 -->
    <JumpAddress
      :visibleModal="visibleModal"
      v-if="visibleModal"
      :config="configFrom"
      @goodsData="handleReceiveData"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "封面",
    ellipsis: true,
    dataIndex: "coverImages",
    scopedSlots: { customRender: "coverImages" },
  },
  {
    title: "跳转地址",
    ellipsis: true,
    dataIndex: "goodsId",
    scopedSlots: { customRender: "goodsId" },
  },
  {
    title: "有效期",
    ellipsis: true,
    dataIndex: "periodValidity",
    scopedSlots: { customRender: "periodValidity" },
  },
  {
    title: "级别",
    ellipsis: true,
    dataIndex: "ranks",
    scopedSlots: { customRender: "ranks" },
  },
  {
    title: "是否过期",
    ellipsis: true,
    dataIndex: "validity",
    scopedSlots: { customRender: "validity" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { filterOpt } from "@/utils/utils";
// import upload from "@/mixins/upload.js";
import { getColor } from "../../utils/color";
import moment from "moment";
import JumpAddress from "./jumpAddress.vue";
import UploadImage from "@/components/uploadImage";
import { API } from "@/apix/explosiveServe";
import { tips } from "../../utils/tips";

export default {
  mixins: [pagination, mixins],
  components: {
    JumpAddress,
    UploadImage,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      configFrom: {
        id: null,
      },
      uploadConfig: {
        maxLength: 1,
        maxSize: 10,
      },
      visibleModal: false, // 弹框显示
      currentIndex: null, // 当前跳转点击行的索引
      ranks: "1", // 级别
      tabVisible: true, // tab页签切换重新请求数据

      goodsAddDis: false, // 添加商品时不允许编辑状态和删除商品
    };
  },
  watch: {
    data: {
      handler() {
        if (!this.data[this.data.length - 1].explosiveGoodsId) {
          this.goodsAddDis = true;
        } else {
          this.goodsAddDis = false;
        }
      },
    },
  },
  methods: {
    getColor,
    filterOpt,
    moment,
    // 增加表格项
    handleAdd() {
      if (this.data.length > 9 && this.ranks === "1") {
        this.$message.error("爆品上限为10条");
      } else {
        this.goodsAddDis = true;
        const newData = {
          coverImages: "",
          ranks: Number(this.ranks),
        };
        // 新增一项默认编辑级别按钮为高级
        this.data = [...this.data, newData];
      }
    },
    // 删除表格项
    onDelete(i, record) {
      if (this.goodsAddDis && record.explosiveGoodsId) {
        this.$message.error("添加商品时不允许编辑商品状态和删除商品");
        return;
      }
      if (!record.explosiveGoodsId) {
        const dataSource = [...this.data];
        this.data = dataSource.filter((item, index) => index !== i);
      } else {
        if (this.data.length < 2) {
          this.pagination.current--;
        }
        let self = this;
        this.$confirm({
          title: "是否确定删除数据?",
          content: "删除后数据无法恢复",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.deleteSenior(record.explosiveGoodsId).then((res) => {
              tips(res, "删除");
              if (res.success) {
                self.getSourceData();
              }
              self.loading = false;
            });
          },
        });
      }
    },
    openModal(id, index) {
      this.currentIndex = index;
      this.visibleModal = true;
    },
    closeModal() {
      this.visibleModal = false;
    },
    handleDate(date, record) {
      record.periodValidity = moment(record.periodValidity).format(
        "YYYY-MM-DD"
      );
    },
    handleTabChange(val) {
      this.ranks = val;
      this.getSourceData();
    },
    // 获取首页所有字典数据
    getSourceData() {
      this.loading = true;
      let detail = {
        ranks: this.ranks,
      };
      this.tabVisible = false;
      API.pageIndex(detail).then((res) => {
        this.data = res.data.map((i) => {
          i.key = i.explosiveGoodsId;
          return i;
        });
        this.loading = false;
        this.tabVisible = true;
      });
    },
    // 处理跳转地址选中传回来的数据
    handleReceiveData(data) {
      this.data[this.currentIndex].goodsId = data.goodsId;
      this.data[this.currentIndex].goodsName = data.goodsName;
    },
    // 设置表格行的背景颜色
    setBackground(record) {
      return record.validity === "过期" ? "tableActive" : "";
    },
    // 编辑状态
    handleSwitch(record, value) {
      let detail = {
        explosiveGoodsId: record.explosiveGoodsId,
        ranks: value,
      };
      let self = this;
      this.$confirm({
        content: "是否确定改变商品级别？",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          API.editRank(detail).then((res) => {
            if (res.success) {
              tips(res, "编辑");
              self.getSourceData();
            }
          });
        },
      });
    },
    // 最后保存
    handleSave(type) {
      this.data.forEach((item) => {
        if (!item.coverImages) {
          this.$message.error("请上传对应的广告封面");
          throw new Error("请上传对应的广告封面");
        } else if (!item.goodsName || item.goodsName === "跳转地址") {
          this.$message.error("请选择跳转地址");
          throw new Error("请选择跳转地址");
        } else if (!item.periodValidity) {
          this.$message.error("请选择一个有效的时间");
          throw new Error("请选择一个有效的时间");
        }
      });
      if (type === "高级") {
        let result = this.data.map((item) => {
          if (!item.ranks) {
            item.ranks = "1";
          }
          return item;
        });
        let self = this;
        this.$confirm({
          title: "是否确定提交数据?",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.addSenior(result).then((res) => {
              tips(res, "保存");
              if (res.success) {
                self.getSourceData();
                self.goodsAddDis = false;
              }
              self.loading = false;
            });
          },
        });
      }
      if (type === "普通") {
        let result = this.data.map((item) => {
          if (!item.ranks) {
            item.ranks = "0";
          }
          return item;
        });
        let self = this;
        this.$confirm({
          title: "是否确定提交数据?",
          cancelText: "取消",
          okText: "确定",
          onOk() {
            self.loading = true;
            API.addSenior(result).then((res) => {
              tips(res, "保存");
              if (res.success) {
                self.getSourceData();
                self.goodsAddDis = false;
              }
              self.loading = false;
            });
          },
        });
      }
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 2rem;
  min-height: 100%;
  background: #fff;
}
</style>
